.about-section {
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.about-section h1,
.about-section h2,
.about-section h3,
.about-section p,
.about-section li,
.about-section a {
  color: var(--text-color) !important;
}

/* Force light text in dark mode */
body.dark-mode .about-section,
body.dark-mode .about-section h1,
body.dark-mode .about-section h2,
body.dark-mode .about-section h3,
body.dark-mode .about-section p,
body.dark-mode .about-section li,
body.dark-mode .about-section a {
  color: #ffffff !important;
}

/* Add any other specific styles for your About section here */