.site-footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  margin-top: 40px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.social-links a {
  color: #fff;
  margin-left: 15px;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}
