body {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f5f5f7;
  color: #1d1d1f;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #f8f9fa;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.main-nav {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.main-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.main-nav ul li {
  margin: 0;
}

.main-nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  padding: 10px 0;
  display: block;
}

.main-nav ul li a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .main-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .main-nav ul li {
    margin-bottom: 10px;
  }
}

main {
  flex: 1 0 auto;
  max-width: 980px;
  margin: 0 auto;
  padding: 40px 22px;
}

h1 {
  font-size: 56px;
  font-weight: 600;
  letter-spacing: -0.005em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.002em;
}

p {
  font-size: 21px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: 0.011em;
}

.blog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.blog-card {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
  margin: 10px;
  padding: 15px;
  width: 300px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card h2 {
  margin-top: 0;
}

.blog-card small {
  color: #666;
}

.full-blog-post {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  overflow-y: auto;
  padding: 20px;
  z-index: 1000;
}

.full-blog-post .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.full-blog-post h1 {
  margin-top: 40px;
}

.full-blog-post .blog-content {
  line-height: 1.6;
  margin-top: 20px;
}

footer {
  background-color: #333;
  color: #fff;
  font-size: 12px;
  padding: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.about-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-section h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.currently-learning {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
}

.learning-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.learning-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.learning-item h3 {
  margin-top: 0;
  color: #007bff;
}

.progress-bar {
  background-color: #e9ecef;
  border-radius: 4px;
  height: 20px;
  margin: 1rem 0;
}

.progress {
  background-color: #28a745;
  height: 100%;
  border-radius: 4px;
  transition: width 0.5s ease-in-out;
}

.resources ul {
  padding-left: 1.5rem;
}

.resources a {
  color: #007bff;
  text-decoration: none;
}

.resources a:hover {
  text-decoration: underline;
}

:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --button-bg: #f0f0f0;
  --button-text: #333333;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home-page {
  padding: 20px;
}

.dark-mode-toggle {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode-toggle:hover {
  opacity: 0.8;
}

/* Light mode styles */
.full-blog-post, .blog-list, .blog-card, .blog-post {
  background-color: #ffffff;
  color: #000000;
}

/* Dark mode styles */
.full-blog-post.dark-mode,
.blog-list.dark-mode,
.blog-card.dark-mode,
.blog-post.dark-mode {
  background-color: #333333;
  color: #ffffff;
}

/* Ensure links are visible in both modes */
a {
  color: #0066cc;
}

.dark-mode a {
  color: #4da6ff;
}

/* Add any other specific styles for your components here */