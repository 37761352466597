:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --button-bg: #f0f0f0;
  --button-text: #333333;
  --box-bg: #f9f9f9;
  --box-border: #e0e0e0;
  --item-bg: #ffffff;
  --item-border: #e0e0e0;
}

body.dark-mode {
  --bg-color: #333333;
  --text-color: #ffffff;
  --button-bg: #555555;
  --button-text: #ffffff;
  --box-bg: #444444;
  --box-border: #555555;
  --item-bg: #555555;
  --item-border: #666666;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body * {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

h1, h2, h3, p, li, a {
  transition: color 0.1s ease;
}

.home-page {
  padding: 20px;
}

.dark-mode-toggle {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.dark-mode-toggle:hover {
  opacity: 0.9;
}

.currently-learning {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--box-bg) !important;
  border: 1px solid var(--box-border) !important;
  border-radius: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.currently-learning h2 {
  color: var(--text-color);
  margin-bottom: 1rem;
  transition: color 0.3s ease;
}

.currently-learning ul {
  list-style-type: none;
  padding-left: 0;
}

.currently-learning li {
  margin-bottom: 0.5rem;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.learning-item {
  background-color: var(--item-bg) !important;
  border: 1px solid var(--item-border) !important;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.learning-item h3,
.learning-item p {
  color: var(--text-color) !important;
}

.progress-bar {
  background-color: var(--box-bg) !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 1rem 0;
}

.progress {
  height: 20px;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.resources a {
  color: #007bff;
  text-decoration: none;
}

body.dark-mode .resources a {
  color: #4da6ff;
}