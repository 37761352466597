:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --box-bg: #f9f9f9;
  --box-border: #e0e0e0;
  --item-bg: #ffffff;
  --item-border: #e0e0e0;
  --card-bg-color: #333;
  --card-text-color: #fff;
}

body.dark-mode {
  --bg-color: #333333;
  --text-color: #ffffff;
  --box-bg: #444444;
  --box-border: #555555;
  --item-bg: #555555;
  --item-border: #666666;
  --card-bg-color: #333;
  --card-text-color: #fff;
}

/* ... other styles ... */

.currently-learning {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--box-bg) !important;
  border: 1px solid var(--box-border) !important;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.learning-item {
  background-color: var(--item-bg) !important;
  border: 1px solid var(--item-border) !important;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.learning-item h3,
.learning-item p {
  color: var(--text-color) !important;
}

.progress-bar {
  background-color: var(--box-bg) !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 1rem 0;
}

.progress {
  height: 20px;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.resources a {
  color: #007bff;
  text-decoration: none;
}

body.dark-mode .resources a {
  color: #4da6ff;
}

.blog-section {
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.blog-section h1,
.blog-section h2,
.blog-section h3,
.blog-section p,
.blog-section li,
.blog-section a {
  color: var(--text-color) !important;
}

/* Force light text in dark mode */
body.dark-mode .blog-section,
body.dark-mode .blog-section h1,
body.dark-mode .blog-section h2,
body.dark-mode .blog-section h3,
body.dark-mode .blog-section p,
body.dark-mode .blog-section li,
body.dark-mode .blog-section a {
  color: #ffffff !important;
}

/* Style for blog post previews */
.blog-post-preview {
  border: 1px solid var(--text-color);
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.blog-post-preview h2 {
  margin-top: 0;
}

.blog-post-preview .read-more {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
}

/* Light mode styles */
.blog-card, .blog-post {
  background-color: #ffffff;
  color: #000000;
  border: none;
  box-shadow: none;
}

/* Dark mode styles */
.dark-mode .blog-card,
.dark-mode .blog-post {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

.dark-mode .blog-card h2,
.dark-mode .blog-post h1 {
  color: #ffffff;
}

.dark-mode .blog-card p,
.dark-mode .blog-post p {
  color: #cccccc;
}

/* Add any other specific styles for your components here */