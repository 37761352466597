.search-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.search-icon button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-icon.expanded form {
  display: flex;
  align-items: center;
}

.search-icon input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-icon form button {
  background: #28a745;
  border-radius: 4px;
  padding: 10px 15px;
  width: auto;
  height: auto;
}

@media (max-width: 768px) {
  .search-icon {
    right: 50%;
    transform: translateX(50%);
  }
}